<template>
  <section class="full-height">
    <div
      v-show="noItems"
      :class="{ 'd-flex': noItems }"
      class="full-height justify-content-center align-items-center flex-column"
    >
      <h3 class="mb-2">
        You currently have no {{ $route.meta.eventType }}s.
      </h3>

      <!-- Add New Event -->
      <portal
        to="breadcrumb-content-header-right"
        :disabled="!events.length"
      >
        <b-button
          variant="primary"
          @click="$router.push({ name: `${$route.meta.eventType}.create` })"
        >
          Add {{ title($route.meta.eventType) }}
        </b-button>
      </portal>
    </div>

    <!-- Items -->
    <b-row>
      <b-col
        v-for="event in events"
        :key="event.id"
        lg="3"
        md="4"
      >
        <b-link :to="{ name: 'event.information', params: { event: event.id } }">
          <card-image
            :text="event.name"
            :image="event.logo_url"
          />
        </b-link>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'
import CardImage from '@/components/cards/CardImage.vue'

import store from '@/store'
import axios from '@/libs/axios'
import { mapMutations } from 'vuex'
import { title } from '@core/utils/filter'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    CardImage,
  },
  data() {
    return {
      events: [],
    }
  },
  computed: {
    noItems() {
      return !this.events.length
    },
  },
  mounted() {
    if (this.noItems) {
      this.UPDATE_CONTENT_AREA_WRAPPER(true)
    }
  },
  methods: {
    title,
    ...mapMutations('app', ['UPDATE_CONTENT_AREA_WRAPPER']),
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const response = await axios.get(`/events?organization_id=${store.state.account.activeOrganization}&type=${to.meta.eventType}`)

      next(vm => {
        vm.events = response.data.data
      })
    } catch (error) {
      flashError(error)
    }

    next()
  },
}
</script>

<style lang="scss" scoped>

</style>
