<template>
  <b-card-group deck>
    <b-card
      class="mb-3"
      no-body
    >
      <b-card-img
        class="card-logo"
        height="150"
        :src="image"
      />

      <b-card-title class="text-center">
        {{ text }}
      </b-card-title>
    </b-card>
  </b-card-group>
</template>

<script>
import {
  BCardGroup, BCard, BCardTitle, BCardImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCardGroup,
    BCard,
    BCardTitle,
    BCardImg,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    text: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
//.card {
//  .card-title {
//    font-weight: 700;
//    font-size: 1.5rem;
//  }
//}

.card-logo {
  margin: auto;
  height: auto;
  max-height: 125px;
  width: auto;
  max-width: 200px;
}
.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card {
  height: 275px;
}
</style>
